export default [
  {
    path: "/apply",
    name: "apply",
    permission:'edit_applicant',
    component: () => import("@/views/admission/apply"),
    meta: {
      public: true,
    },
    children: [
      {
        path: "",
        name: "apply",
        permission:'edit_applicant',
        component: () => import("@/views/admission/apply/components/apply"),
        meta: {
          public: true,
        },
      },
      {
        path: "/apply/:type",
        name: "apply-types",
        permission:'edit_applicant',
        component: () => import("@/views/admission/apply/components/types.vue"),
        meta: {
          public: true,
        },
      },
      {
        path: "/apply/:type/:id",
        name: "apply-form",
        permission:'edit_applicant',
        component: () => import("@/views/admission/apply/form.vue"),
        meta: {
          public: true,
        },
        children: [
          {
            path: "/apply/:type/:id/:success",
            name: "apply-success",
            permission:'edit_applicant',
            component: () =>
              import("@/views/admission/apply/components/success.vue"),
              meta: {
                public: true,
              },
          },
        ],
      },
    ],
  },

  {
    path: '/students',
    name: 'students',
    query:{nam:'test'},
    component: () => import('@/views/students/student/index'),
    meta: { pageTitle: 'students', breadcrumb: [{ text: 'students'} , { text: 'Students Data' }] },
  },
  {
    path: '/students/transfer',
    name: 'internal-transfer',
    //permission: 'access_users',
    component: () => import('@/views/students/student/transfer/index'),
    meta: { pageTitle: 'internal-transfer', breadcrumb: [{ text: 'students'} , { text: 'Internal Transfers' }] },
  },
  {
    path: '/tranfer/internal/add',
    name: 'add_internal_transfer',
    component: () => import('@/views/students/student/transfer/internal_transfer'),
    meta: { pageTitle: 'request internal transfer', breadcrumb: [{to: '/tranfer/internal', text: 'Internal Transfer'},{ text: 'Add', active: true }] },
  },
  {
    path: '/students/:id/',
    name: 'student-show',
    permission: 'access_users',
    component: () => import('@/views/students/student/show'),
    meta: {
      public: true,
      pageTitle: 'students',
      breadcrumb: [{ text: 'students'} , { to: '/students', text: 'Students Data' }, { text: 'student', active: true }],
    },
  },
  {
    path: '/students/edit/:student_id/transfer/:transfer_id',
    name: 'edit-transfer',
    permission: 'access_users',
    component: () => import('@/views/students/student/transfer/edit'),
    meta: {
      public: true,
      pageTitle: 'internal-transfer',
      breadcrumb: [{ text: 'students'} , { to: '/students/transfer', text: 'Internal Transfer' }, { text: 'student', active: true }],
    },
  },
  {
    path: '/students/edit/:id/:details',
    name: 'student-edit',
    permission: 'access_users',
    component: () => import('@/views/students/student/edit'),
    meta: {
      public: true,
      pageTitle: 'students',
      breadcrumb: [{ text: 'students'} ,{ to: '/students', text: 'Students Data' }, { text: 'student', active: true }],
    },
  },

  // {
  //   path: '/students/transfer/:id/',
  //   name: 'transfer',
  //   permission: 'access_users',
  //   component: () => import('@/views/students/student/transfer/transfer'),
  //   meta: {
  //     public: true,
  //     pageTitle: 'internal-transfer',
  //     breadcrumb: [{ to: '/students/transfer', text: 'Internal Transfer' }, { text: 'student', active: true }],
  //   },
  // },

  {
    path: '/students/:student_id/transfer/:transfer_id',
    name: 'equivalent-transfer',
    permission: 'access_users',
    component: () => import('@/views/students/student/transfer/equivalent'),
    meta: {
      public: true,
      pageTitle: 'internal-transfer',
      breadcrumb: [{ text: 'students'} , { to: '/students/transfer', text: 'Internal Transfer' }, { text: 'student', active: true }],
    },
  },

  {
    path: '/applicants_pg',
    name: 'applicants_pg',
    component: () => import('@/views/admission/applicants/index'),
    meta: { pageTitle: 'applicants', breadcrumb: [{ text: 'admissionPG' } , { text: 'applicants' }] },
  },

  {
    path: '/applicants',
    name: 'applicants',
    component: () => import('@/views/admission/applicants/index'),
    meta: { pageTitle: 'applicants', breadcrumb: [{ text: 'admissionUG' } , { text: 'applicants' }] },
  },
  {
    path: '/applicants/edit',
    name: 'edit_applicant',
    permission: 'access_users',
    component: () => import('@/views/admission/applicants/edit'),
    meta: { pageTitle: 'applicant',navActiveLink:'applicants', breadcrumb: [ { text: 'admission' } , { to: '/edit_applicant', text: 'applicants' }, { text: 'applicant', active: true }] },
  },
  {
    path: "/applicants/:id",
    name: "applicant",
    permission: "access_users",
    component: () => import("@/views/admission/applicants/show"),
    meta: {
      pageTitle: "applicant",
      navActiveLink: "applicants",
      breadcrumb: [
        { text: "admission" },
        { to: "/applicants", text: "applicants" },
        { text: "applicant", active: true },
      ],
    },
    children: [
      {
        name: "applicants_edit",
        path: "/applicants/edit/:type/:id/:secretKey/:name?",
        redirect: { path: "/applicants/edit/:type/:id/:secretKey/nationality" },
        permission: "public",
        component: () => import("@/views/admission/applicant_information"),
        meta: {
          pageTitle: "admissionForm",
          breadcrumb: [{ text: "admissionForm", active: true }],
        },
        children: [
          {
            path: "/applicants/edit/:type/:id/:secretKey/:name?",
            name: "admissionForm_edit",
            permission: "access_users",
            component: () =>
              import("@/views/admission/applicant_information/form"),
            meta: {
              pageTitle: "admissionForm",
              breadcrumb: [
                { text: "admissionForm" },
                { params: "name", text: "nationality", active: true },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/admission-reports',
    name: 'admission-reports',
    permission: 'access_users',
    component: () => import('@/views/admission/reports/show'),
    meta: { pageTitle: 'applicant', breadcrumb: [{ text: 'reports' },{ text: 'admission-reports', active: true }] },
  },
  {
    path: '/finanicials-settings-reports',
    name: 'finanicials-settings-reports',
    permission: 'access_users',
    component: () => import('@/views/reports/FinancialSettings'),
    meta: { pageTitle: 'applicant', breadcrumb: [{ text: 'reports' },{ text: 'Financials Settings Reports', active: true }] },
  },
  {
    path: '/reports',
    name: 'reports',
    permission: 'access_reports',
    component: () => import('@/views/reports/Report'),
    meta: { pageTitle: 'reports', breadcrumb: [{ text: 'reports' }] },
  },
  {
    path: '/graduat-reports',
    name: 'graduats-report',
    permission: 'access_users',
    component: () => import('@/views/reports/Graduats'),
    meta: { pageTitle: 'graduates', breadcrumb: [{ text: 'reports' },{ text: 'Graduates', active: true }] },
  },
  {
    path: '/registrations-report',
    name: 'registrations-report',
    permission: 'access_report_Registrations',
    component: () => import('@/views/reports/Registrations'),
    meta: { pageTitle: 'Registrations', breadcrumb: [{ text: 'reports' },{ text: 'Registrations Report', active: true }] },
  },
  {
    path: '/payments-reports',
    name: 'payments-report',
    permission: 'access_users',
    component: () => import('@/views/reports/Payments'),
    meta: { pageTitle: 'payments', breadcrumb: [{ text: 'reports' },{ text: 'Payments', active: true }] },
  },
  {
    path: '/accepted_applicants_pg',
    name: 'accepted_applicants_pg',
    component: () => import('@/views/admission/approved/index'),
    meta: { pageTitle: 'accepted_applicants', breadcrumb: [{ text: 'admissionPG' } , { text: 'Accepted_Applicants' }] },
  },
  {
    path: '/accepted_applicants',
    name: 'accepted_applicants',
    component: () => import('@/views/admission/approved/index'),
    meta: { pageTitle: 'accepted_applicants', breadcrumb: [{ text: 'admissionUG' } , { text: 'Accepted_Applicants' }] },
  },
  {
    path: '/accepted_applicants/:id',
    name: 'accepted_applicant_show',
    permission: 'access_users',
    component: () => import('@/views/admission/approved/show'),
    meta: { pageTitle: 'applicant',navActiveLink:'accepted_applicants', breadcrumb: [{ text: 'admission' } , { to: '/accepted_applicants', text: 'Students Paid' }, { text: 'Student Paid', active: true }] },
  },
  {
    path: '/distribution_applicants_pg',
    name: 'distribution_applicants_pg',
    component: () => import('@/views/admission/distrubation/index'),
    meta: { pageTitle: 'applicants', breadcrumb: [{ text: 'admissionPG' } , { text: 'distribution_applicants' }] },
  },
  {
    path: '/distribution_applicants',
    name: 'distribution_applicants',
    component: () => import('@/views/admission/distrubation/index'),
    meta: { pageTitle: 'applicants', breadcrumb: [{ text: 'admissionUG' } , { text: 'distribution_applicants' }] },
  },
  {
    path: '/accepted_applicants/:id',
    name: 'distribution_applicant_show',
    permission: 'access_users',
    component: () => import('@/views/admission/distrubation/show'),
    meta: { pageTitle: 'applicant',navActiveLink:'distribution_applicants', breadcrumb: [{ text: 'admission' } , { to: '/distribution_applicants', text: 'distribution_applicants' }, { text: 'Distribution Applicant', active: true }] },
  },
  // {
  //   path: '/admission/terms/edit/:id?',
  //   name: 'edit_admission_term',
  //   component: () => import('@/views/admission/terms/edit'),
  //   meta: { pageTitle: 'terms', breadcrumb: [{ text: 'admission' } , { to: '/admission/terms', text: 'admission_terms' }, { text: 'term' }] },
  // },
  // {
  //   path: '/admission/terms',
  //   name: 'admission_terms',
  //   component: () => import('@/views/admission/terms/index'),
  //   meta: { pageTitle: 'admission_term',navActiveLink:'admission_terms', breadcrumb: [{ text: 'admission' } , { text: 'admission_terms' }] },
  // },

  // {
  //   path: '/admission/terms/:id',
  //   name: 'admission_term',
  //   component: () => import('@/views/admission/terms/show'),
  //   meta: { pageTitle: 'admission_term',navActiveLink:'admission_terms', breadcrumb: [{ text: 'admission' } , { to: '/admission/terms', text: 'admission_terms' }, { text: 'term', active: true }] },
  // },
  {
    path: '/admission/transfer_students',
    name: 'transfer_students',
    component: () => import('@/views/admission/TransferStudents/index'),
    meta: { pageTitle: 'transfer_students', breadcrumb: [{ text: 'admission' } , { text: 'transfer_students' }] },
  },
  {
    path: '/admission/transfer_students/equivalence/:user_id',
    name: 'equivalence_student',
    component: () => import('@/views/admission/TransferStudents/equivalent'),
    meta: { pageTitle: 'equivalence_student', breadcrumb: [{ text: 'admission' } , { text: 'transfer_students' }] },
  },
  {
    path: '/transfered_distribution',
    name: 'transfered_distribution',
    component: () => import('@/views/admission/transferedDistrubation/index'),
    meta: { pageTitle: 'transfered_distribution', breadcrumb: [{ text: 'admission' } , { text: 'transfered_distribution' }] },
  },
  {
    path: '/transfered_student_paid',
    name: 'transfered_student_paid',
    component: () => import('@/views/admission/transferedApproved/index'),
    meta: { pageTitle: 'transfered_student_paid', breadcrumb: [{ text: 'admission' } , { text: 'transfered_student_paid' }] },
  },
  {
    path: '/certificates',
    name: 'certificates',
    permission: 'access_certificate',
    component: () => import('@/views/students/certificates/index'),
    meta: { pageTitle: 'Certificates', breadcrumb: [{ text: 'students'} , { text: 'Certificates' }] },
  },
  {
    path: '/certificates/show/:id',
    name: 'certificate',
    permission: 'show_certificate',
    component: () => import('@/views/students/certificates/show'),
    meta: { pageTitle: 'certificate',navActiveLink:'certificates', breadcrumb: [{ text: 'students'} , { to: '/certificates', text: 'Certificates' }, { text: 'Certificate', active: true }] },
  },
  {
    path: '/certificates/update/:id',
    name: 'certificate-update',
    permission: 'edit_certificate',
    component: () => import('@/views/students/certificates/update'),
    meta: { pageTitle: 'certificate',navActiveLink:'certificates', breadcrumb: [{ text: 'students'} , { to: '/certificates', text: 'certificates' }, { text: 'certificate', active: true }] },
  },
  {
    path: '/certificates/external/:id',
    name: 'certificate-external',
    permission: 'show_certificate',
    component: () => import('@/views/students/certificates/external'),
    meta: { pageTitle: 'certificate',navActiveLink:'certificates', breadcrumb: [{ text: 'students'} , { to: '/certificates', text: 'certificates' }, { text: 'certificate', active: true }] },
  },
  {
    path: '/my_advising',
    name: 'my_advising',
    component: () => import('@/views/students/advising/index'),
    meta: { pageTitle: 'Advising', breadcrumb: [{ text: 'Advising', active: true }] },
    props: { all_advising: false },

  },
  {
    path: '/advising',
    name: 'advising',
    component: () => import('@/views/students/advising/index'),
    meta: { pageTitle: 'Advising', breadcrumb: [{ text: 'Advising', active: true }] },
    // props: { all_advising: true },
  },
  {
    path: '/advising/registration/:id',
    name: 'advising_registration',
    component: () => import('@/views/for_student/offering/registration'),
    meta: { pageTitle: 'offering_registration',navActiveLink:'advising', breadcrumb: [{ text: 'study'} , { to: '/advising', text: 'Advising' }, { text: 'Registration', active: true }] },
  },
  {
    path: '/correction_requests/:id',
    name: 'show_correction_requests',
    component: () => import('@/views/for_student/correction_requests/show'),
    meta: { pageTitle: 'My Correction Requests',navActiveLink:'my_correction_requests', breadcrumb: [{ to: '/correction_requests', text: 'Correction Requests' }, { text: 'Correction Request', active: true }] },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/for_student/service/serviceRequests/index'),
    meta: { pageTitle: 'services', navActiveLink: 'services',breadcrumb: [{ text: 'students' } , {text : 'services' , active : true}] },
  },
  {
    path: '/services/:id',
    name: 'other_show',
    component: () => import('@/views/for_student/service/serviceRequests/show'),
    meta: { pageTitle: 'Other Service',navActiveLink: 'services', breadcrumb: [{ text: 'students' } , {text : 'services' , to : '/services'},{text: 'show' , active: true}] },
  },
  {
    path:"/other/edit/:id",
    name: 'edit_other',
    component: () => import('@/views/for_student/service/serviceRequests/edit'),
    meta: { pageTitle: 'Other Service', breadcrumb: [{ text: 'Students' } , {text : 'Other Service' , active : true}] },
  },
  {
    path: '/equivalent-report',
    name: 'equivalent-report',
    component: () => import('@/views/admission/reports/equivalent/index.vue'),
    meta: { pageTitle: 'equivalent', breadcrumb: [{ text: 'equivalent'} ] },
  },
  {
    path: '/foreigner-report',
    name: 'foreigner-report',
    component: () => import('@/views/admission/reports/equivalent/index.vue'),
    meta: { pageTitle: 'foreigner', breadcrumb: [{ text: 'foreigner'} ] },
  },

  {
    path: '/scholarships',
    name: 'scholarships',
    component: () => import('@/views/students/scholarships/index.vue'),
    meta: { pageTitle: 'scholarships', breadcrumb: [{ text: 'scholarships'} ] },
  },

]
